.ff-input {
    display: grid;
    grid-template-columns: minmax(0, 1fr) min-content;
    grid-template-rows: min-content min-content;
    grid-template-areas: 
        "label label"
        "input clear";
}
.ff-input label {
    grid-area: label;
}
.ff-input input {
    grid-area: input;
}
.ff-input textarea {
    grid-area: input;
}
.ff-input .clear-btn {
    grid-area: clear;
    margin-left: 0.5em;
    align-self: self-start;
}


.ff-input textarea {
    color: #fff;
    background-color: var(--dark-3);
    border-radius: var(--small-radius);
    border: 1px solid var(--dark-1);
    padding: 0 1em;
    min-height: 4lh;
}
  
.ff-input textarea:disabled {
    background: #444;
    cursor: not-allowed;
}
.ff-input textarea::placeholder {
    font-style: italic;
}

.ff-input input[type="color"] {
    display: block;
}
.ff-input input:not([type="color"]) {
    color: #fff;
    background-color: var(--dark-3);
    border-radius: var(--small-radius);
    border: 1px solid var(--dark-1);
    line-height: 2em;
    padding: 0 1em;
    font-size: 1em;
}
  
.ff-input input:not([type="radio"]) {
    box-shadow: inset rgba(0,0,0,0.4) 0px 0px 30px 0px; 
}
  
.ff-input input:disabled {
    background: #444;
    color: var(--light-1);
    cursor: not-allowed;
}
  
.ff-input input[type="radio"]:checked:disabled {
    background: var(--form-control-accent-disabled);
}
  
.ff-input input::placeholder {
    font-style: italic;
}
  
  
.ff-input input[type="radio"]:hover {
    cursor: pointer;
}

/* https://moderncss.dev/pure-css-custom-styled-radio-buttons/ */
.ff-input input[type="radio"] {
    appearance: none;
    margin: 0;
    padding: 3px;
    /* font: inherit;
    color: currentColor; */
    width: 1.15em;
    height: 1.15em;
    /* border: 0.15em solid var(--dark-2); */
    border-radius: 50%;
    /* transform: translateY(-0.075em);
    display: grid;
    place-content: center; */
}

.ff-input input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transform: translateY(-0.075em);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(#fff);
}

.ff-input input[type="radio"]:checked::before {
    transform: scale(1);
}
.ff-input input[type="radio"]:checked {
    border: 0.15em solid var(--dark-2);
    background-color: var(--form-control-accent);
}