.selection-container {
    margin-bottom: 1em;
}
.dark-card.selectable-answers {
    padding: 0.5em;
    gap: 0.5em;
    /* background-color: var(--primary); */
    margin: 0.5em 0;
}
.dark-card.selectable-answers > * {
    padding: 0;
}