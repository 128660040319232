/* Standard Button */
.button {
    display: flex;
    border: none;
    border-radius: var(--small-radius);
    color: var(--font-color);
    background-color: rgba(0,0,0,0);
    padding: 0em 1.2em;
    justify-content: center;
    align-items: center;
    height: 2.5em;
    font-weight: 600;
    gap: 0.5em;
    flex-shrink: 0;
    /* box-shadow: 0 14px 28px 
    rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); */
}

.button:enabled:hover {
    cursor: pointer;
    backdrop-filter: brightness(80%);
}
.button:disabled {
    color: var(--light-1) !important;
    cursor: default;
}



/** Color Specifiers **/
/* Primary */
.button[variant~="primary"] {
    background-color: var(--primary);
}
.button[variant~="primary"]:disabled {
    color: rgb(102, 102, 102);
    background-color: rgb(168, 168, 168);
    cursor: default;
}

/* Warning */
.button[variant~="warn"] {
    background-color: palevioletred;
}

/* Outline */
.button[variant~="outline"] {
    border: 1px solid var(--grey);
    background-color: rgba(28, 28, 28, 0.5);
}


/* Link */
.button[variant~="link"] {
    display: inline-flex;
    color: var(--hyperlink);
    padding: 0;
    margin: 0;
    font-weight: 400;
    background-color: rgba(0,0,0,0);
    text-decoration: underline;
    height: auto;
}


/** Icon Specifiers **/
/* Icon */
.button[variant~="icon"] {
    padding: 0.2em;
    height: inherit;
}



/** Shape Specifiers **/
.button[variant~="circle"] {
    width: 3em;
    height: 3em;

    border-radius: 100px;
}
.button[variant~="circle"][variant~="small"] {
    width: 2em;
    height: 2em;

    border-radius: 100px;
}



/** Size Specifiers **/
.button[variant~="inline"] {
    width: 2em;
    height: 2em;
    /* margin-left: 0.5em; */
}
.button[variant~="icon"][variant~="inline"] > svg {
    font-size: 1.25em !important;
}
.button[variant~="icon"][variant~="small"] > svg {
    font-size: 1rem !important;
}

/* icon subtle */
.button[variant="icon subtle"] {
    padding: 0.2em;
    color: inherit;
    filter: brightness(50%);
}




/* Google Auth */
.button[variant="auth"] {
    background-color: var(--light-5);
    color: var(--dark-3);
}

/* Google Auth */
/* .button[variant|="google-auth"] {
    background-color: var(--light-4);
    color: var(--dark-3);
} */
