.ff-select {
    display: grid;
}
.ff-select select {
    background-color: var(--dark-3);
    border-radius: var(--small-radius);
    border: 1px solid var(--dark-1);
    line-height: 2em;
    padding: 0 1em;
    font-size: 1em;
    box-shadow: inset rgba(0,0,0,0.4) 0px 0px 30px 0px; 
    min-height: 2em;
}
.ff-select select:hover {
    cursor: pointer;
    background-color: var(--dark-2);
}

/* .ff-select .dropdown-menu {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    display: none;
    width: 100%;

    border: 1px solid var(--grey);
    border-radius: var(--small-radius);
    bottom: 0.5em;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 80;

    background-color: var(--dark-3);
}
.ff-select .dropdown-menu.show {
    opacity: 1;
    visibility: visible;
    display: block;
}

.ff-select .dropdown-item {
    padding: 0 1em;
    line-height: 2em;
    min-height: 2em;
    white-space: nowrap;
}
.ff-select .dropdown-item:hover {
    cursor: pointer;
    background-color: var(--dark-2);
}

.ff-select .dropdown-item:first-of-type {
    padding-top: 0.3em;
    border-radius: var(--small-radius) var(--small-radius) 0 0;
}

.ff-select .dropdown-item:last-of-type {
    padding-bottom: 0.3em;
    border-radius: 0 0 var(--small-radius) var(--small-radius);
} */